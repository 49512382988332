<template>
  <div class="col-12 g">
    <div class="card">
        <div class="card-header">
            <h5>
                المستخدمين
            </h5>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <th>
                            المستخدم
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            كلمة المرور
                        </th>
                        <th>
                            تم التفعيل
                        </th>
                        <th>
                            تاريخ الانضمام
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user._id">
                            <td>
                                {{ user.name }}
                            </td>
                            <td>
                                {{ user.phone }}
                            </td>
                            <td>
                                {{ user.password }}
                            </td>
                            <td>
                                {{ user.activated ? 'فعال' : user.otp }}
                            </td>
                            <td>
                                {{ user.joindate }}
                            </td>
                            <td>
                                <button class="btn btn-sm btn-secondary" @click="join(user)"><i class="fa fa-external-link"></i> الدخول بحسابه</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            users: [],
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/users', {
            jwt: g.user.jwt,
        }).then(function(r){
            g.users = r.response
        })
    },
    methods: {
        join(user){
            window.open(window.location.href.includes('.com') ? 'https://bioraw.brmja.com/login?phone=' + encodeURI(user.phone) + '&password=' + encodeURI(user.password) : 'http://localhost:8081/login?phone=' + encodeURI(user.phone) + '&password=' + encodeURI(user.password))
        }
    }
}
</script>

<style>

</style>